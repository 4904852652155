import React from 'react'

export const RightArrow = _ => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5548 11.0702L12.0909 6.14673L13.3635 5L21 11.8812L20.3637 12.4546L13.3635 18.7624L12.0909 17.6156L17.5548 12.6922H3V11.0702H17.5548Z" />
  </svg>
);

export const DownArrow = _ => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.8596 35.1096L35.7065 24.1818L38 26.727L24.2376 42L23.0909 40.7274L10.4752 26.727L12.7687 24.1818L22.6157 35.1096L22.6157 6L25.8596 6L25.8596 35.1096Z" fill="white"/>
  </svg>
)

export const Facebook = _ => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM10 10H8V12H10V18H13V12H14.82L15 10H13V9.167C13 8.689 13.096 8.5 13.558 8.5H15V6H12.596C10.798 6 10 6.792 10 8.308V10Z" fill="#45C2CA"/>
  </svg>
)

export const Twitter = _ => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18.5 8.778C18.059 8.974 17.584 9.106 17.086 9.166C17.595 8.861 17.984 8.379 18.169 7.804C17.693 8.086 17.166 8.291 16.605 8.401C16.157 7.922 15.516 7.623 14.809 7.623C13.219 7.623 12.051 9.106 12.41 10.646C10.365 10.543 8.55 9.563 7.336 8.074C6.691 9.18 7.002 10.628 8.098 11.361C7.695 11.348 7.316 11.237 6.984 11.053C6.957 12.193 7.775 13.26 8.959 13.498C8.613 13.592 8.233 13.614 7.847 13.54C8.16 14.518 9.071 15.229 10.147 15.249C9.11 16.061 7.807 16.424 6.5 16.27C7.59 16.969 8.883 17.376 10.273 17.376C14.845 17.376 17.427 13.515 17.271 10.052C17.753 9.706 18.17 9.272 18.5 8.778Z" fill="#45C2CA"/>
</svg>
)

export const Instagram = _ => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM12 7.082C13.602 7.082 13.792 7.088 14.425 7.117C16.052 7.191 16.81 7.962 16.885 9.576C16.913 10.209 16.919 10.398 16.919 12C16.919 13.602 16.913 13.792 16.885 14.424C16.81 16.037 16.053 16.81 14.425 16.884C13.792 16.912 13.603 16.919 12 16.919C10.398 16.919 10.208 16.913 9.576 16.884C7.946 16.809 7.191 16.035 7.116 14.424C7.088 13.792 7.081 13.602 7.081 12C7.081 10.398 7.088 10.208 7.116 9.576C7.19 7.961 7.948 7.19 9.576 7.116C10.208 7.087 10.398 7.082 12 7.082ZM12 6C10.37 6 10.167 6.007 9.526 6.037C7.346 6.137 6.136 7.346 6.036 9.526C6.007 10.167 6 10.371 6 12C6 13.63 6.007 13.834 6.036 14.474C6.136 16.653 7.346 17.864 9.526 17.964C10.167 17.993 10.37 18 12 18C13.63 18 13.834 17.993 14.475 17.964C16.651 17.864 17.866 16.655 17.964 14.474C17.993 13.834 18 13.63 18 12C18 10.371 17.993 10.167 17.964 9.526C17.866 7.349 16.655 6.136 14.475 6.037C13.834 6.007 13.63 6 12 6ZM12 8.919C10.299 8.919 8.919 10.298 8.919 12C8.919 13.702 10.299 15.081 12 15.081C13.701 15.081 15.081 13.702 15.081 12C15.081 10.299 13.701 8.919 12 8.919ZM12 14C10.895 14 10 13.105 10 12C10 10.896 10.895 10 12 10C13.104 10 14.001 10.895 14.001 12C14.001 13.105 13.104 14 12 14ZM15.202 8.078C14.805 8.078 14.482 8.4 14.482 8.798C14.482 9.195 14.804 9.518 15.202 9.518C15.6 9.518 15.923 9.196 15.923 8.798C15.923 8.4 15.601 8.078 15.202 8.078Z" fill="#45C2CA"/>
</svg>
)