import styled from 'styled-components'

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;
  padding-left: 24px;
  padding-right: 24px;

  @media (min-width: 768px) {
    padding-left: 48px;
    padding-right: 48px;
  }
`
export default Wrapper
