import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'

import Header from './header'
import Footer from './footer'
//import './layout.css'
import 'normalize.css'
import '../styles/global.css'

import { THEME } from '../styles/theme'

const Layout = ({ children, location, headerTheme }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={THEME}>
        <>
            <Header location={location} headerTheme={headerTheme} />
            {children}
            <Footer />
        </>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
