import { Link } from 'gatsby'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import throttle from 'lodash.throttle'

import Wrapper from '../layouts/wrapper'
import Button from '../layouts/button'
import LogoCircle from '../svgs/logo-circle.svg'


const FixedHeader = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 108px;
  background-color: ${
    props => props.sticky ?
      'rgba(255,255,255,0.95)'
      : 'transparent'
  };
  transition: background-color 0.5s ease-out,
              height 0.35s ease-out;

  @media (min-width: 1024px) {
    height: ${
      props => props.sticky ?
        '96px'
        : '138px'
    };
  }
  
  @media (min-width: 1400px) {
    padding: 24px;
  }
`

const FixedHeaderWrapper = styled(Wrapper)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Nav = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  /* display: ${
    props => props.active ?
      'flex'
      : 'none'
  }; */
  opacity: ${
    props => props.active ?
      '1'
      : '0'
  };
  visibility: ${
    props => props.active ?
      'visible'
      : 'hidden'
  };
  transform: ${
    props => props.active ?
      'translateX(0)'
      : 'translateX(100%)'
  };
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: #FFF;
  transition: 
    transform 0.25s ease-out,
    opacity 0.25s ease-out,
    ${props => props.active ?
      'visibility 0s linear'
      : 'visibility 0s linear 0.25s'
    };
    

  @media (min-width: 1024px) {
    position: static;
    display: block;
    transform: none;
    opacity: 1;
    visibility: visible;
    min-height: initial;
    background-color: transparent;
  }
`

const NavLink = styled(Link)`
  position: relative;
  color: ${
    props => (props.current ? props.theme.color.primary : props.theme.color.black)
  };
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.25s ease-out;

  @media (min-width: 1024px) {
    font-size: 1rem;
    color: ${
      props => {
        if (props.current) return props.theme.color.primary
        else {
          if (props.lighttheme && !props.sticky) return '#FFF'
          if (props.lighttheme && props.sticky) return '#4F4F4F'
          return '#4f4f4f'
        }
      }
    };
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    @media (min-width: 1024px) {
      margin-right: 48px;
      margin-bottom: 0;
    }
  }

  &:hover {
    color: #45C2CA;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -12px;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${
      props => props.current ?
        props.theme.color.primary
        : 'transparent'
    };
    transition: background-color 0.5s ease-out;
  }
`

const LogoWrapper = styled(Link)`
  width: 60px;
  height: 60px;
  z-index: 1;

  @media (min-width: 1024px) {
    width: 90px;
    height: 90px;
    transform-origin: left center;
    transition: transform 0.25s ease-out;
  
    ${({sticky}) => 
      !!sticky && css`
        transform: scale(0.5);
      `
    }
  }
  
  @media (min-width: 1400px) {
    position: absolute;
    left: -96px;
  }

  > svg {
    fill: ${
      props => {
        if (props.current) return props.theme.color.primary
        else {
          if (props.lighttheme && !props.sticky) return '#FFF'
          if (props.lighttheme && props.sticky) return '#4F4F4F'
          return '#4f4f4f'
        }
      }
    };
  }
`

const CTAWrapper = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }

  @media (min-width: 1400px) {
    position: absolute;
    top: 50%;
    right: -96px;
    transform: translateY(-50%); 
  }
`

const MobileMenuToggle = styled.div`
  position: absolute;
  right: 24px;
  width: 48px;
  height: 48px;
  background-image: 
    linear-gradient(
      to bottom, 
      ${
        props => {
          if (props.active) return props.theme.color.black
          else {
            if (props.lighttheme && !props.sticky) return '#FFF'
            if (props.lighttheme && !props.sticky) return '#FFF'
            if (props.lighttheme && props.sticky) return '#4F4F4F'
            return '#4f4f4f'
          }
        }
      } 0%, 
      ${
        props => {
          if (props.active) return props.theme.color.black
          else {
            if (props.lighttheme && !props.sticky) return '#FFF'
            if (props.lighttheme && !props.sticky) return '#FFF'
            if (props.lighttheme && props.sticky) return '#4F4F4F'
            return '#4f4f4f'
          }
        }
      } 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  cursor: pointer;
  transition: background-size 0.2s ease-out;

  @media (min-width: 1024px) {
    display: none;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 48px;
    height: 2px;
    margin-top: -1px;
    background-color: ${
      props => {
        if (props.active) return props.theme.color.black
        else {
          if (props.lighttheme && !props.sticky) return '#FFF'
          if (props.lighttheme && !props.sticky) return '#FFF'
          if (props.lighttheme && props.sticky) return '#4F4F4F'
          return '#4f4f4f'
        }
      }
    };
    transition: transform 0.3s ease-out;
  }

  &:before {
    transform: translateY(-500%);
  }

  &:after {
    transform: translateY(500%);
  }

  ${({active}) => !!active && css`
      background-size: 0 2px;

      &:before {
        transform: translateY(0) rotateZ(45deg);
      }

      &:after {
        transform: translateY(0) rotateZ(-45deg);
      }
    `
  }
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenuVisible: false,
      sticky: false,
      lighttheme: this.props.headerTheme === 'light',
      location: this.props.location
    }
    this.handleScroll = throttle(this.handleScroll, 100)
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = _ => {
    if (window.scrollY >= 50 && !this.state.sticky) {
      this.setState({ sticky: !this.state.sticky })
    } 
    if (window.scrollY < 50 && this.state.sticky) {
      this.setState({ sticky: !this.state.sticky })
    } 
  }

  toggleMobileMenu = _ => {
    this.setState({ mobileMenuVisible: !this.state.mobileMenuVisible })
  }

  render() {
    
    const { location, sticky, lighttheme } = this.state

    return(
      <FixedHeader sticky={sticky}>
        <FixedHeaderWrapper>
          <LogoWrapper lighttheme={lighttheme ? 1 : 0} sticky={sticky ? 1 : 0} to='/'>
            <LogoCircle />
          </LogoWrapper>
          <Nav active={this.state.mobileMenuVisible}>
            <NavLink lighttheme={lighttheme ? 1 : 0} sticky={sticky ? 1 : 0} current={location === '/' ? 1 : 0} to="/">Home</NavLink>
            <NavLink lighttheme={lighttheme ? 1 : 0} sticky={sticky ? 1 : 0} current={location === '/work/' ? 1 : 0} to="/work/">Work</NavLink>
            {/* <NavLink lighttheme={lighttheme ? 1 : 0} sticky={sticky ? 1 : 0} to="/">Web design</NavLink>
            <NavLink lighttheme={lighttheme ? 1 : 0} sticky={sticky ? 1 : 0} to="/">Web maintenance</NavLink>
            <NavLink lighttheme={lighttheme ? 1 : 0} sticky={sticky ? 1 : 0} to="/">SEO</NavLink> */}
            <NavLink lighttheme={lighttheme ? 1 : 0} sticky={sticky ? 1 : 0} current={location === '/contact/' ? 1 : 0} to='/contact/'>Contact</NavLink>
          </Nav>
          <MobileMenuToggle 
            lighttheme={lighttheme ? 1 : 0} 
            sticky={sticky ? 1 : 0}
            onClick={this.toggleMobileMenu} 
            active={this.state.mobileMenuVisible}
          />
          <CTAWrapper>
            <Button mod='secondary' to='/contact/'>
              Free quote
            </Button>
          </CTAWrapper>
        </FixedHeaderWrapper>
      </FixedHeader>
    )
  }
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
