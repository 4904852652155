import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import ogImage from '../images/designweb-og-image.jpg';
import twitterImage from '../images/designweb-og-image.jpg';

import appleTouchIcon from '../images/favicon/apple-touch-icon.png';
import favicon32 from '../images/favicon/favicon-32x32.png';
import favicon16 from '../images/favicon/favicon-16x16.png';
import manifest from '../images/favicon/manifest.json'
import maskIcon from '../images/favicon/safari-pinned-tab.svg';
import faviconICO from '../images/favicon/favicon.ico'

function SEO({ description, lang, meta, pageKeywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {

        const metaDescription = description || data.site.siteMetadata.description

        const language = lang || data.site.siteMetadata.language

        const keywords = pageKeywords || data.site.siteMetadata.keywords

        return (
          <Helmet
            htmlAttributes={{
              language
            }}
            title={title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: /^\/.{1,}/.test(ogImage) ? data.site.siteMetadata.siteUrl + ogImage : ogImage,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: /^\/.{1,}/.test(twitterImage) ? data.site.siteMetadata.siteUrl + twitterImage : twitterImage,
              },
              {
                name: `keywords`,
                content: keywords
              }
            ].concat(meta)}
            link={[
              { rel: 'apple-touch-icon', sizes: '180x180', href: `${appleTouchIcon}` },
              { rel: 'shortcut icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
              { rel: 'shortcut icon', type: 'image/png', sizes: '16x16', href: `${favicon16}` },
              { rel: 'manifest', href: `${manifest}` },
              { rel: 'mask-icon', color: '#00aba9', href: `${maskIcon}` },
              { rel: 'shortcut icon', href: `${faviconICO}` },     
            ]}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        keywords
      }
    }
  }
`
