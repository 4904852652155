import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import Wrapper from '../layouts/wrapper'
import Section from '../layouts/section'

import { Facebook, Twitter, Instagram } from '../svgs/icons'

const NavWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  @media (min-width: 768px) {
    flex-direction row;
    flex-wrap: wrap;
  }
`

const NavCol = styled.div`
  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: auto;
  }

  > nav {
    ${props =>
      props.stackNav &&
      css` 
        display: flex;
        flex-direction: column;
      `
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
  }
`

const CopyrightWrapper = styled(Wrapper)`
  display: flex;
  flex-direction column;
  justify-content: space-between;
  padding-bottom: 24px;

  @media (min-width: 1024px) {
    flex-direction row;
  }

  > *:not(:last-child) {
    margin-bottom: 12px;

    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
  }
`

const ContactLink = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #45C2CA;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`


const FooterNavTitle = styled.h3`
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;


  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }
`

const FooterNavLink = styled(Link)`
  color: #4f4f4f;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

const SocialLink = styled.a`
  &:not(:last-child) {
    margin-right: 12px;
  }
`

const Footer = _ => {
  
  return (
    <footer>
      <Section paddedTop paddedBottom bgWhite>
        <NavWrapper>
          <NavCol stackNav>
            <FooterNavTitle>Contact us</FooterNavTitle>
              <nav>
                <ContactLink href='mailto:designwebnewzealand@gmail.com'>
                  designwebnewzealand@gmail.com
                </ContactLink>
                  {/* <ContactLink to='/'>09 - 123 4567</ContactLink> */}
              </nav>
          </NavCol>
          <NavCol stackNav>
              <FooterNavTitle>Services</FooterNavTitle>
              <nav>
                  <FooterNavLink to='/contact/'>Web design and development</FooterNavLink>
                  <FooterNavLink to='/contact/'>Web maintenance plans</FooterNavLink>
                  <FooterNavLink to='/contact/'>Search engine optimisation (SEO)</FooterNavLink>
              </nav>
          </NavCol>
          <NavCol stackNav>
              <FooterNavTitle>About us</FooterNavTitle>
              <nav>
                  {/* <FooterNavLink to='/'>About Design Web</FooterNavLink> */}
                  <FooterNavLink to='/work/'>Our work</FooterNavLink>
                  <FooterNavLink to='/contact/'>Contact us</FooterNavLink>
                  {/* <FooterNavLink to='/'>Our team</FooterNavLink> */}
              </nav>
          </NavCol>
          <NavCol>
              <FooterNavTitle>Follow us</FooterNavTitle>
              <nav>
                  <SocialLink href='https://www.facebook.com/designwebnz' target='_blank'>
                    <Facebook />
                  </SocialLink>
                  {/* <SocialLink to='/'>
                    <Twitter />
                  </SocialLink>
                  <SocialLink to='/'>
                    <Instagram />
                  </SocialLink> */}
              </nav>
          </NavCol>
        </NavWrapper>
      </Section>
      <Section bgWhite>
          <CopyrightWrapper>
              <small>&copy; Copyright 2019 Emiray Ltd All Right Reserve, DesignWeb is the trading name of Emiray Web Department.</small>
              {/* <FooterNavLink to='/'><small>Privacy & Policy</small></FooterNavLink> */}
          </CopyrightWrapper>
      </Section>
    </footer>
  )
}

export default Footer
