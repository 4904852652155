import styled, { css } from 'styled-components'

const Section = styled.section`

  ${props =>
    props.bgSecondary &&
    css`
      background-color: #EDF2F6;
    `
  }

${props =>
    props.bgWhite &&
    css`
      background-color: #FFF;
    `
  }

  ${props =>
    props.bgGradient &&
    css`
      background-image: linear-gradient(53.99deg, rgba(237, 242, 246, 0.6) 14.99%, rgba(237, 242, 246, 0) 78.84%);
    `
  }

  ${props =>
    props.paddedTop &&
    css`
      padding-top: 48px;

      @media (min-width: 768px) {
        padding-top: 72px;
      }
    `
  }

  ${props =>
    props.paddedBottom &&
    css`
      padding-bottom: 48px; 

      @media (min-width: 768px) {
        padding-bottom: 72px;
      }
    `
  }
`

export default Section