export const THEME = {
  color: {
    primary: '#45C2CA',
    secondary: '#FF7C6E',
    black: '#4F4F4F',
    white: '#FFFFFF'
  },
  breakPoints: {
    tablet: 768
  }
}