import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import { RightArrow } from '../svgs/icons'

const StyledButton = styled(Link)`
  display: inline-block;
  border-radius: 50px;
  border: 0;
  padding: 12px 24px;
  background-color: #CCC;
  font-size: 0;
  text-decoration: none;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15);
  overflow: hidden;
  cursor: pointer;
  transition: opacity 0.25s ease-out,
              box-shadow 0.25s ease-out;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.8;
    box-shadow: 0 7px 15px 0 rgba(0,0,0,0.25);
  }

  ${({mod, theme}) =>
    mod === 'primary' &&
    css`
      background-color: ${theme.color.primary};
      color: ${theme.color.white};

      > svg {
        fill: ${theme.color.white};
      }
    `
  }

  ${({mod, theme}) =>
    mod === 'secondary' &&
    css`
      background-color: ${theme.color.white};
      color: ${theme.color.secondary};

      > svg {
        fill: ${theme.color.secondary};
      }
    `
  }

  /* ${props =>
    props.shadow &&
    css`
      box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15);
    `
  } */

  > span,
  svg {
    vertical-align: middle;
  }

  > span {
    margin-right: 6px;
    font-size: 1rem;
    font-weight: 600;
  }
`
const Button = ({children, to, mod, as}) => {
  return (
    <StyledButton mod={mod} to={to} as={as}>
      <span>
        {children}
      </span>
      <RightArrow />
    </StyledButton>
  )
}

export default Button